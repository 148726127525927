
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"

export const query = graphql`
  query {
    isologismos2021: allFile(
      filter: { relativePath: { eq: "data/isologismos-2021.pdf" } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    },
    apotelesmata2021: allFile(
      filter: { relativePath: { eq: "data/katastasi-apotelesmatwn-xrisis-2021.pdf" } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    },
    isologismos2022: allFile(
      filter: { relativePath: { eq: "data/isologismos-2022.pdf" } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    },
    apotelesmata2022: allFile(
      filter: { relativePath: { eq: "data/katastasi-apotelesmatwn-xrisis-2022.pdf" } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`

const FinancialsPage = props => {
  const financialsPerYear = [
    {
      year: 2021,
      isologismos: props.data.isologismos2021.edges,
      apotelesmata: props.data.apotelesmata2021.edges
    },
    {
      year: 2022,
      isologismos: props.data.isologismos2022.edges,
      apotelesmata: props.data.apotelesmata2022.edges
    },
  ]

  return (
    <Layout>
      <Head title="Financials" />
      <div className="centered">
        <p>
          Χρηματοοικονομικές Καταστάσεις
        </p>
        {
          financialsPerYear.map((financial) => (
            <ul className="vim-categories">
              {financial.year}
              <ul>
                Ισολογισμός {financial.year}:
                <li>
                  {financial.isologismos.map(({ node }, index) => (
                    <a href={node.publicURL} target="_blank" key={index}>
                      Κατεβάστε το αρχείο
                    </a>
                  ))}
                </li>
              </ul>
              <ul>
                Κατάσταση Αποτελεσμάτων Χρήσης {financial.year}:
                <li>
                  {financial.apotelesmata.map(({ node }, index) => (
                    <a href={node.publicURL} target="_blank" key={index}>
                      Κατεβάστε το αρχείο
                    </a>
                  ))}
                </li>
              </ul>
            </ul>
          ))
        }
      </div>
    </Layout>
  )
}

export default FinancialsPage
